export const getDomain = () =>{
    const url = new URL(window.location.href)
    const arr = url.host.split('.')
    return `${arr[arr.length - 2]}.${arr[arr.length - 1]}`
}

var url_of_app
if(window.location.href.includes('/store.desklamp.io/')){
    //we are in the production STORE
    url_of_app="https://app.desklamp.io"
}
else if(window.location.href.includes("localhost")){
    url_of_app = "http://localhost:5001"
}
else if(window.location.href.includes("staging")){
    url_of_app = "https://stagingapp-912873465.desklamp.io"
}
else if(window.location.href.includes("main")){
    url_of_app = "https://mainapp-912873465.desklamp.io"
}
else{
    url_of_app = "https://devapp-912873465.desklamp.io"
}

export const appURL = url_of_app

// export const appURL = window.location.href.includes("localhost") ? "http://localhost:5001": (window.location.href.includes("912873465")?
//     "https://devapp-912873465.desklamp.io" //testing production environment
//     :
//     "https://app.desklamp.io" //production environment
// )