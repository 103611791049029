import logo from './logo.svg';
import './App.css';
import Amplify, {Auth} from 'aws-amplify'
import awsconfig from './aws-exports';
import { useState, useEffect } from 'react';
import AuthBox from './components/Authbox/Authbox';
import { BrowserRouter as Router, Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import { getCookie } from './utils/CookieHelper';
import {signInWithTokens} from './utils/Authenticator'
import {getDomain, appURL} from './utils/url'

//Refer to the below document for code documentation of this file:
//https://docs.google.com/document/d/1un8UMhyX60dm4vTzVwvVwJ2GV5obtqIPE6yAokpsIW8/edit#

const domain = getDomain()


const configureAmplify = (jwtToken) =>{

    if(jwtToken){
        //This code appends the user attributes (like email ID) to the graphQL query
        Amplify.configure({
            ...awsconfig,
            API: {
                aws_appsync_graphqlEndpoint: awsconfig.aws_appsync_graphqlEndpoint,
                aws_appsync_region: awsconfig.aws_appsync_region,
                aws_appsync_authenticationType: awsconfig.aws_appsync_authenticationType ,
                graphql_headers: async () => ({
                    'Authorization': jwtToken // (await Auth.currentSession()).getIdToken().getJwtToken()
                })
            }
        })
    }
    else{
        Amplify.configure(awsconfig);
        Auth.configure(awsconfig);
    }

}


configureAmplify()

function App(props) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    //never call setStateUserAttributes directly. Always use the wrapper setUserAttributes
    const [userAttributes, setStateUserAttributes ] = useState(null) 

    const onInputChange = (e) =>{
        if(e.target.id==="email") setEmail(e.target.value)
        if(e.target.id==="password") setPassword(e.target.value)
    }

    const getTokensFromCookies = () =>{
        const access_token = getCookie('CognitoAccessToken')
        const refresh_token = getCookie('CognitoRefreshToken')
        const id_token = getCookie('CognitoIdToken')

        const allTokensExist = access_token && refresh_token && id_token

        return {access_token, refresh_token, id_token, allTokensExist}
    }

    const setUserAttributes = async (userAttributes) =>{
        setStateUserAttributes(userAttributes)
        var jwtToken = ""
        try{
            jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        }
        catch{
            console.log("jwtToken being set to empty string on signOut")
        }
        configureAmplify(jwtToken)
    }

    const authorizeSession = () =>{
        /**
         * Checks if a user is currently authenticated. If yes, sets the session data in frontend
         * @returns true, if the session was successfully authorized. false, otherwise 
         */

        console.log("Auth going to start authorizeSession")

        return new Promise(resolve=>{

            Auth.currentAuthenticatedUser().then(user=>{

                localStorage.setItem("amplify-signin-with-hostedUI", "true")
                localStorage.setItem("amplify-redirected-from-hosted-ui", "true")

                console.log("Auth user exists and set in Amplify storage: ", user)
                setUserAttributes(user.attributes)    

                resolve(true)

            }).catch(err=>{
                console.warn("Auth no currently authenticated user: ", err)
                // signOutThisSession() //This safety measure will cause problems if any subdomain does global sign out.

                resolve(false)
            })

        })

        
    }

    const initUser = async() =>{

        const {access_token, refresh_token, id_token, allTokensExist} = getTokensFromCookies()

        if(allTokensExist){


            const alreadyAuthorized = await authorizeSession()

            if(!alreadyAuthorized){
                //user not already authenticated
                console.log("Auth: user not authenticated from before. Trying with cookies")

                signInWithTokens(id_token, refresh_token, access_token, {
                    errorCallback: () =>{
                        console.warn("Auth couldn't sign in with the given tokens")
                        signOutThisSession()
                    },
                    successCallback: authorizeSession
                })
            }
        }
        else{
            console.log("Auth couldn't get all cookies. Proceeding as guest. ")
            signOutThisSession()
        }
        
    }

    const signOutThisSession =async () =>{
        //signs user out in the current session
        try{
            await Auth.signOut();
            setUserAttributes(null)
            console.warn("Auth Signed Out")
        }
        catch(error){
            console.error("Auth Error Signing Out!")
        }
    }

    const manualSignIn = () =>{

        const {access_token, refresh_token, id_token, allTokensExist} = getTokensFromCookies()

        if(allTokensExist){

            signInWithTokens(id_token, refresh_token, access_token, {
                errorCallback: () =>{
                    console.warn("Auth couldn't sign in with the given tokens")
                    signInThroughApp()
                },
                successCallback: authorizeSession
            })

        }
        else{
            signInThroughApp()
        }

    }

    const signInThroughApp = () =>{
        const currentURL = window.location.href
        window.location.href=`${appURL}/login?redirect=${currentURL}`
    }

    const signOutThroughApp = () =>{
        signOutThisSession()
        const currentURL = window.location.href
        window.location.href=`${appURL}/logout?redirect=${currentURL}`
    }

    
    // const url = new URL(window.location.href)
    // const category = url.searchParams.get("cat")
    // const subcategory = url.searchParams.get("subcat")
    // const id = url.searchParams.get("id")
    // const [details, setDetails] = useState({
    //     id: id,
    //     category: category,
    //     subcategory: subcategory
    // });
    
    // const [searchParams] = useSearchParams();

    
    
    useEffect(()=>{initUser()}, [])

    

    return (
        <div className="App">
            <Navbar userAttributes={userAttributes} initUser = {initUser} manualSignIn={manualSignIn} signOutThroughApp={signOutThroughApp} signOutThisSession={signOutThisSession} />
            <Routes>
                {
                    process.env.NODE_ENV==="development" || process.env.NODE_ENV == ""?
                        <Route path='/login' exact element={
                            <AuthBox setUserAttributes={setUserAttributes} setUser={() =>{
                                initUser(); window.location.replace("/")
                            }} setAuthBoxStatus={()=>{}}
                            />
                        } />
                    :null
                }
                
                <Route path='/list' element={<Home type = {"list"} userAttributes = {userAttributes}/>}/>
                <Route path='/book' element={<Home type = {"book"} userAttributes = {userAttributes}/>}/>
                <Route  path='/' element={<Home type = {"list"}  userAttributes = {userAttributes}/>} />
            </Routes>
                
            <Footer />

        </div>
    );
}

export default App;
