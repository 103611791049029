import React, { Component } from 'react'
import './AuthBox.css'
import close from '../../icons/close_black.png'

import {Auth, API} from 'aws-amplify'
import {updateUser} from '../../graphql/mutations'
import {getUser} from '../../graphql/queries'
import desklamp_light from '../../icons/DesklampWordmark.svg'
import loading_gif from '../../icons/loading_auth.gif'



// import thinkerEle from './Presentation.001.jpeg'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  
export class AuthBox extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            email:"",
            name:"",
            firstName: "", 
            lastName: "",
            userType: "", //the user pool group
            profession: "", //what is to be displayed in the textbox
            password:"",
            confirmPassword: "", 
            loading:false,
            verificationCode: "", 
            showConfirmPassword: false,
            screen: props.provider || props.read? 'signIn':props.user?"account":"signUp" ,//0: signIn, 1:signUp, 2:account, 3:confirmVerCode
            dims: [window.innerWidth, window.innerHeight],
            errorMessage: null,
            message: null,
            showDropdown: false,
            successPopup: "",
        }
        this.width = props.device!=="mobile"?600:320
        this.height=440
        this.errorMessages = {
            "UserNotFoundException": "Uh oh! We couldn't find an account with that email.",
            "NotAuthorizedException": "Wrong password. Try again or click Forgot Password.",
            "UsernameExistsException": "An account with that email already exists. Please sign in.",
            "UserNotConfirmedException": "This email has not been verified. Please contact support@desklamp.io or use a different email.",
            "Username cannot be empty": "Email cannot be empty",
            "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": "Password must be at least 8 characters",
            "Custom auth lambda trigger is not configured for the user pool.": "Please enter a password.",
            "social":"Social sign-in is coming soon",
            "Cannot reset password for the user as there is no registered/verified email or phone_number": "Uh oh! We couldn't find an account with that email.",
        }


        this.clearForm = {
            errorMessage: "", 
            firstName: "", 
            verificationCode: "",
            lastName: "",
            password: "", 
            userType: "",
            profession: "",
            showConfirmPassword: false,
        }
        
    }

    onEnterKey = async(e) =>{
        if (e.key === 'Enter') {
            switch(this.state.screen)
            {
                case "signIn":
                    await this.signIn()
                    break;
                case "signUp":
                    await this.signUp()
                    break;
                case "confirmSignUp":
                    await this.confirmSignUp()
                    break;
                case "passwordReset":
                    if(this.state.showConfirmPassword) await this.forgotPasswordSubmit()
                    else await this.sendResetPasswordCode()
                    break;
                case "newPassword":
                    await this.newPasswordAndSignIn()
                    break;

                default:
                    break;
            }
          }
        
    }

    newPasswordAndSignIn = async() =>{
        if(this.state.newPassword!==this.state.confirmNewPassword){
            this.setErrorMessage({message:"Passwords don't match."})
        }
        else await this.signIn()
        // if (this.props.redirect){
        //     window.location.replace('/')
        //     return
        // }
    }
   

    signIn = async(options = {updateUserEnvironment : true}) => {
        /**
         * @param updateUserEnvironment true to update electron/web field for User in dynamo
         */

        

        this.setState({
            loading:true
        })
        try {
            let user
            if (this.state.screen != "newPassword") {
                user  = await Auth.signIn(this.state.email, this.state.password || this.password);
            }
            else{
                user = await Auth.completeNewPassword(this.user, this.state.newPassword)
                user = await Auth.signIn(this.state.email, this.state.newPassword)
                this.updateIdentityIdInDynamo()
            }
            if (user.challengeName == 'NEW_PASSWORD_REQUIRED'){
                // user signed in with temp credentials
                // show new password screen

                this.user = user
                this.setState({
                    password: "",
                    newPassword: "",
                    screen : 'newPassword',
                    loading : false
                })
                return
            }
            console.log("Signed In!", user)
            console.log("setUser AuthBox: ", this.props.setUser)
            
            
            if(this.props.environment==="electron"){

                // const {ipcRenderer} = window
                window.electronAPI.send("signInCompleted", {
                    given_name: user.attributes.given_name,
                    family_name: user.attributes.family_name,
                    email: user.attributes.email,
                    sub: user.attributes.sub
                })
            }
            this.password = ""
            const {environment} = this.props
            console.log("environment: ", environment)
            this.setState({
                ...this.clearForm,
            }, () =>{
                console.log("closing authbox")
                this.closeAuthBox()
            })
            console.log("updateUserEnv: ", options.updateUserEnvironment)
            if(options.updateUserEnvironment){
                var userDetails = {}
                
                console.log("getting user")
                const userObject = await API.graphql({ query: getUser, variables: { id: this.state.email }});

                console.log("got User: ", userObject)

                if(environment==="electron"){
                    userDetails = {
                        id: this.state.email,
                        desktop: "true",
                        _version: userObject._version
                    }
                }

                else{
                    userDetails = {
                        id: this.state.email,
                        web: "true",
                        _version: userObject._version
                    }
                }

                console.log("new update: ", userDetails)
                let resp = await API.graphql({query:updateUser, variables: {input: userDetails} }  
                    )
            }
            this.props.setUserAttributes(user.attributes, 0)



        } catch (error) {
            console.log("error: ", error)
            this.setErrorMessage(error)
            this.setState({
                screen : "signIn"
            })
        }
        this.setState({
            loading:false
        })
    }

    signUp = async() =>{
        const {email, password, firstName, lastName, userType} = this.state
        this.setState({loading: true})
        const fName = capitalizeFirstLetter(firstName).trim()
        const lName = capitalizeFirstLetter(lastName).trim()
        if(userType && fName && lName && email && password){
            try {
                const { user } = await Auth.signUp({
                    username: email,
                    password: password,
                    attributes: {
                        email,  
                        given_name: fName ,
                        family_name: lName , 
                        "custom:profession": userType,       
                    }
                });
                console.log(user);
                this.setScreen(null ,"confirmSignUp")
                const name = fName + " " + lName
                
            } catch (error) {
                console.log('code:', error.code);
                console.log('message:', error.message);
                this.setErrorMessage(error)
            }
        }
        else{
            console.log("Ho ho")
            if(!fName) this.setErrorMessage({message: "Please fill in your first name."})
            else if(!lName) this.setErrorMessage({message: "Please fill in your last name."})
            else if(!email) this.setErrorMessage({message: "Please fill in your email."})
            else if(!password) this.setErrorMessage({message: "Please fill in a password."})
            else if(!userType) this.setErrorMessage({message: "Please fill in your field of work."})
            else this.setErrorMessage({message: "Please fill in all the fields."})
        }
        
        this.setState({
            loading:false
        })
    }

    resendVerificationCode = async() =>{
        this.setState({
            loading:false
        })
        try {
            await Auth.resendSignUp(this.state.email);
            this.setState({
                message: "Code resent successfully"
            })
        } catch (err) {
            console.log('error resending code: ', err);
            this.setState({
                errorMessage: "There was an unexpected error resending the code. Try again in some time"
            })
        }
        this.setState({
            loading:false
        })
    }

    updateIdentityIdInDynamo = () =>{
        const {email} = this.state
        var userDetails = {
            id: email,
            _version: 1
        }
        if(this.props.environment==="electron"){
            userDetails = {
                ...userDetails, 
                desktop: "true"
            }
        }
        else{
            userDetails = {
                ...userDetails,
                web: "true"
            }
        }

        Auth.currentCredentials().then(creds => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    const {identityId} = creds
                    console.log("identityID: ", identityId)
                    // const {sub, email, family_name, given_name} = user.attributes
                    userDetails = {
                        ...userDetails,
                        identityID: identityId,
                    };
                    API.graphql({query:updateUser, variables: {input: userDetails} }  
                    ).then(data=>{
                        console.log("identityID: ", identityId)
                        console.log("User updated successfully in dynamodb: ", data)
                    }).catch(err=>{
                        console.log("ERROR updating user in dynamo: ", err)
                    });                    
                })
                .catch(err => console.log(err));
        }).catch(err=>{
            console.log("ERROR!")
        });
    }

    confirmSignUp = async() => {
        const {email, verificationCode} = this.state
        this.setState({
            loading:true
        })
        try {
            console.log("Starting confirm sign up")
    
            await Auth.confirmSignUp(email, verificationCode)
            // this.setState({
            //     successPopup : "Email successfully verified"
            // })
            // setTimeout(() =>{
            //     this.setState({successPopup:""})
            // }, 4000)
            await this.signIn({updateUserEnvironment: false})
            this.updateIdentityIdInDynamo()

        } catch (err) {
          console.log("error confirming sign up", err)
          this.setErrorMessage(err)
        }
        this.setState({
            loading:false
        })
    }

    verifyCurrentUserAttribute = async(attr) =>{
        this.setState({loading: true})
        try{
            await Auth.verifyCurrentUserAttribute(attr)
            this.setScreen(null, "verifyEmail")
        }
        catch (e){
            console.log("error confirming sign up", e)
            this.setErrorMessage(e)
        }
        this.setState({loading: false})

    }

    verifyCurrentUserAttributeSubmit = async(attr) =>{
        this.setState({loading: true})
        try{
            await Auth.verifyCurrentUserAttributeSubmit(attr)
            console.log('phone_number verified');
            if(attr==="email_verified") await this.signIn()
        }
        catch(e) {
            console.log('failed with error', e);
        }
        this.setState({loading: false})

    }

    setErrorMessage = async (err) =>{
        console.log("Auth error message: ", err)
        if(err.code ==="UserNotConfirmedException"){

            // To initiate the process of verifying the attribute like 'phone_number' or 'email'
            try {
                await Auth.resendSignUp(this.state.email);
                this.setState({
                    screen: "confirmSignUp"
                })
            } catch (err) {
                console.log('error resending code: ', err);
                this.setState({
                    errorMessage: "There was an unexpected error resending the code. Try again in some time"
                })
            }

            
            // this.setState({loading: true})
            // this.verifyCurrentUserAttribute('email_verified')
        }
        else if(err.message === "Cannot reset password for the user as there is no registered/verified email or phone_number"){
            try {
                await Auth.resendSignUp(this.state.email);
                this.setState({
                    screen: "confirmSignUp"
                })
            } catch (err) {
                console.log('error resending code: ', err);
                this.setState({
                    errorMessage: "There was an unexpected error resending the code. Try again in some time"
                })
            }
        }
        else{
            var message = this.errorMessages[err.code] || this.errorMessages[err.message]
            this.setState({
                errorMessage: message || err.message
            })
        }
        

    }

    federatedSignIn = (e) =>{
        console.log("target: ", e.target.name)
        if(e.target.name){
            Auth.federatedSignIn({provider: e.target.name})
        }
        else{
            Auth.federatedSignIn()
        }
    }

    signOut = async () => {
        if(this.props.environment!=="electron"){
            //this.props.closeAllTabs()
        }
        console.log('PROPS = ', this.props)
        this.setState({
            loading:false
        })
        // if(!this.props.allowGuest){
        
        // }
        
        console.log("loading false")

        try {
            var e = await Auth.signOut();
            this.props.setUser(null)
            if(this.props.environment==="electron"){
                window.electronAPI.send("signOut")
            }
            console.log("signed Out: ", e)

        } catch (error) {
            console.log('error signing out: ', error);
        }
        
    }

    onChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value,
            errorMessage:''
        })
    }

    setScreen = (e, screenId = null) =>{
        // console.log("SET SCREEN: ", e, num)
        var newScreen
        if (e) newScreen = e.target.id
        else newScreen = screenId
        if(newScreen==="9"){
            this.setState({
                errorMessage: "Account settings is coming soon"
            })
        }
        else{
            this.password = this.state.password
            this.setState({
                screen: newScreen, 
                ...this.clearForm
            })
        }
    }

    onResize = () =>{
        this.setState({
            dims: this.props.getWindowSize()
        })

    }

    sendResetPasswordCode = () =>{
        this.setState({loading: true})
        Auth.forgotPassword(this.state.email)
            .then(data => {
                this.setState({
                    showConfirmPassword: true
                })
            })
            .catch(err => {
                this.setErrorMessage(err)
            });
        this.setState({
            loading: false 
        })

    }

    


    forgotPasswordSubmit = () =>{
        this.setState({
            loading: true
        })
        if(this.state.password === this.state.confirmPassword){
            Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.password)
            .then(async(data) => {
                this.setState({
                    message: "Password was reset successfully"
                })
                await this.signIn()
                this.updateIdentityIdInDynamo()
            })
            .catch(err => {
                this.setErrorMessage(err)
            });

        }
        else{
            this.setErrorMessage({message: "Passwords don't match"})
        }
        this.setState({
            loading: false, 
        })
    }

    componentDidMount(){
        window.addEventListener("resize", this.onResize) 
        if(this.props.environment==="web") this.props.setImmortal(false)
        Auth.currentAuthenticatedUser().then(user => console.log("User: ", user)).catch(()=>{
            console.log("Noone is Signed In!")
        });

    }
    
    componentWillUnmount(){
        window.removeEventListener("resize", this.onResize)
    }

    handleClick = (e) =>{
        if(e.target.classList.contains("auth-drop")){
            return
        }
        else{
            this.toggleDropdown()
        }
    }

    setUserType = (e) =>{
        this.setState({
            userType: e.target.id,
            profession: e.target.innerHTML
        })
        this.toggleDropdown()
    }

    toggleDropdown = () =>{
        console.log("toggle fropfown")
        if(this.state.showDropdown){
            //closing the dropdown
            document.removeEventListener("click", this.handleClick)
        }
        else{
            //opening the dropdown
            document.addEventListener("click", this.handleClick)
        }
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    closeAuthBox = (e) =>{
        console.log("closing auth box")
        this.props.setAuthBoxStatus(false)
    }



    componentDidUpdate(prevProps, prevState){
        if(JSON.stringify(prevProps.user)===JSON.stringify(this.props.user)){
            return
        }
        else{
            this.setState({
                screen: this.props.user?"account":"signIn", 
                firstName: "", 
                lastName: "",
                email: "", 
                password: "", 
                error: "",
            })
        }
    }

    
    
    render() {
    
        var screen , leftHeader, leftSubheader, leftFooterAction, leftFooterid, leftFooter
        var screenNum = this.state.screen
        switch(this.state.screen){
            case "newPassword":
                leftHeader = "Welcome back"
                leftSubheader = "You're just a few clicks away from a great reading session"
                leftFooter = "Don't have an account?"
                leftFooterAction = "Sign up"
                leftFooterid="signUp"

                screen = 
                    <>
                        {/* <a className="authbox-text comingsoon">Social sign-in coming soon</a> */}
                        {/* <FacebookSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/>
                        <GoogleSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/> */}
                        {/* <p className="authbox-text social-message">If you're signing in with social, we'll take you to your web browser and then bring you back here</p> */}
                        
                        <img src={desklamp_light} alt="Desklamp" className="desklamp-logo-auth"></img>
                        {/* <p className="authbox-or">or</p> */}
                        
                        <p className="authbox-error">{this.state.errorMessage}</p>
                        {
                            this.state.errorMessage?null:
                            <div className="authbox-greet">Welcome to Desklamp! <br/> Please reset your password to continue.</div>
                        }

                        <input disabled={true} onKeyPress ={this.onEnterKey} className="authbox-input" type="email" name="email" onChange={this.onChange} value={this.state.email} placeholder="Email address"></input>
                        {/* <input onKeyPress ={this.onEnterKey}  className="authbox-input" type="password" name="password" onChange={this.onChange} value={this.state.password}  placeholder="Password"></input> */}
                        <input onKeyPress = {this.onEnterKey} className="authbox-input" type="password" name = "newPassword" onChange={this.onChange} value ={this.state.newPassword} placeholder = "New Password"></input>
                        <input onKeyPress = {this.onEnterKey} className="authbox-input" type="password" name = "confirmNewPassword" onChange={this.onChange} value ={this.state.confirmNewPassword} placeholder = "Confirm Password"></input>

                        <button className="authbox-button signin" onClick={this.newPasswordAndSignIn}>
                            Sign In
                            {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}
                        </button>
                        {this.props.environment === "electron" && this.props.allowGuest ? <div className="proceed-guest"> No internet? <a className="authbox-link inline" id="passwordReset" onClick={this.props.gotoguest}>Proceed as Guest</a></div>:null}
                        {this.props.device === "mobile" ? <div className="proceed-guest"> Don't have an account? <a className="authbox-link inline" id={leftFooterid} onClick={this.setScreen}>Sign Up</a></div>:null}


                    </>
                
                
                break;
            case "signIn": //0
                //Sign in
                leftHeader = "Welcome back"
                leftSubheader = "You're just a few clicks away from a great reading session"
                leftFooter = "Don't have an account?"
                leftFooterAction = "Sign up"
                leftFooterid="signUp"

                screen = 
                    <>
                        {/* <a className="authbox-text comingsoon">Social sign-in coming soon</a> */}
                        {/* <FacebookSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/>
                        <GoogleSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/> */}
                        {/* <p className="authbox-text social-message">If you're signing in with social, we'll take you to your web browser and then bring you back here</p> */}
                        
                        <img src={desklamp_light} alt="Desklamp" className="desklamp-logo-auth"></img>
                        {/* <p className="authbox-or">or</p> */}
                        <p className="authbox-error message">
                            {this.props.subHeader}
                        </p>
                        <p className="authbox-error">{this.state.errorMessage}</p>

                        <input onKeyPress ={this.onEnterKey} className="authbox-input" type="email" name="email" onChange={this.onChange} value={this.state.email} placeholder="Email address"></input>
                        <input onKeyPress ={this.onEnterKey}  className="authbox-input" type="password" name="password" onChange={this.onChange} value={this.state.password}  placeholder="Password"></input>
                        {
                            this.props.device!=="mobile"?
                            <a className="authbox-link" id="passwordReset" onClick={this.setScreen}>Forgot Password?</a>
                            :null
                        }
                        <button className="authbox-button signin" onClick={this.signIn}>
                            Sign In
                            {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}
                        </button>
                        {this.props.environment === "electron" && this.props.allowGuest ? <div className="proceed-guest"> No internet? <a className="authbox-link inline" id="passwordReset" onClick={this.props.gotoguest}>Proceed as Guest</a></div>:null}
                        {this.props.device === "mobile" ? <div className="proceed-guest"> Don't have an account? <a className="authbox-link inline" id={leftFooterid} onClick={this.setScreen}>Sign Up</a></div>:null}


                    </>
                
                
                break;
            case "signUp"://1
                //Sign Up

                leftHeader = "Create an account"
                leftSubheader = "Sign up to back up your work on the cloud, collaborate with teams, and share your documents and notebooks"
                leftFooter = "Already a member?"
                leftFooterAction = "Sign in"
                leftFooterid="signIn"

                const streams = ['Arts', 'Business', 'Engineering', 'Humanities and Social Sciences', 'Journalism', 'Law', 'Medicine', 'Science', 'Other']
                const streams_emoji = {
                    'Arts': '🎭' ,
                    'Business':'‍💼',
                    // 'Education':'🎓',
                    'Engineering':'🛠️',
                    'Humanities and Social Sciences':'📖',
                    'Journalism':'📰',
                    'Law':'⚖️',
                    'Medicine':'🩺',
                    'Science':'⚛️',
                    'Other':'✨',
                }

                screen = 
                <>
                    {/* <a className="authbox-text comingsoon">Social sign-in coming soon</a> */}
                    
                    {/* <FacebookSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/>
                    <GoogleSignIn environment={this.props.environment} setErrorMessage = {this.setErrorMessage}/> */}
                    {/* <p className="authbox-text social-message">If you're signing in with social, we'll take you to your web browser and then bring you back here</p> */}
                    <img src={desklamp_light} alt="Desklamp" className="desklamp-logo-auth"></img>
                    
                    {/* <p className="authbox-or">or</p> */}
                    <p className="authbox-error">{this.state.errorMessage}</p>
                    <input onKeyPress ={this.onEnterKey} className="authbox-input mini first-name" type="text" name="firstName" onChange={this.onChange} value={this.state.firstName} placeholder="First Name" autoComplete="off"></input>
                    <input onKeyPress ={this.onEnterKey} className="authbox-input mini last-name" type="text" name="lastName" onChange={this.onChange} value={this.state.lastName} placeholder="Last Name" autoComplete="off"></input>

                    {/* <input onKeyPress ={this.onEnterKey} className="authbox-input" name="name" onChange={this.onChange} value={this.state.name} placeholder="Full Name"></input> */}
                    <input onKeyPress ={this.onEnterKey} className="authbox-input" type="email" name="email" onChange={this.onChange} value={this.state.email} placeholder="Email (Institute email preferred)" ></input>
                    <input onKeyPress ={this.onEnterKey} className="authbox-input" type="password" name="password" onChange={this.onChange} value={this.state.password}  placeholder="Create password" ></input>
                    {this.state.showDropdown?
                        <div className="auth-dropdown auth-drop">
                            {
                                streams.map(stream=>
                                <div className="auth-option auth-drop" key={stream} id={stream} onClick={this.setUserType}>{stream +' '+ streams_emoji[stream]}</div>
                                )
                            }
                            {/* <div className="auth-option auth-drop" id="Professor" onClick={this.setUserType}>Professor</div>
                            <div className="auth-option auth-drop" id="PhD" onClick={this.setUserType}>PhD Researcher</div>
                            <div className="auth-option auth-drop" id="Other" onClick={this.setUserType}>Other</div> */}
                        </div>:null
                    }
                    
                    <input readOnly={true} className="authbox-input auth-drop" onClick={this.toggleDropdown} value={this.state.profession} placeholder="Field"></input>

                    <button className="authbox-button signin" onClick={this.signUp}>
                        Sign Up
                        {this.state.loading?"loading...":null}
                    </button>
                    <p className="authbox-tos">By clicking on "Sign Up", you agree to the Desklamp <a id="terms" href="https://desklamp.io/legal?page=terms" target="_blank" rel="noreferrer" >Terms</a> 
                     and <a id="privacy" href="https://desklamp.io/legal?page=privacy" rel="noreferrer" target="_blank" onClick = {this.sendLegalAnalytics}>Privacy Policy</a>.</p>

                    {this.props.device === "mobile" ? <div className="proceed-guest"> Already have an account? <a className="authbox-link inline" id={leftFooterid} onClick={this.setScreen}>Sign In</a></div>:null}

                </>
                


                break;

            case "account": //2
                //Account
                var firstName, lastName, email
                firstName = this.props.user?this.props.user.firstName : "Signing"
                lastName = this.props.user? this.props.user.lastName: "Out ..."
                email = this.props.user? this.props.user.email: "Signing Out ..."
                screen = 
                <>
                    <div className="authbox-initials">
                        {firstName[0]}{lastName[0]}
                    </div>
                    <p className="authbox-p"><b>Name: </b>{firstName} {lastName}</p>
                    <p className="authbox-p"><b>Email: </b> {email} </p>
                    <button className="authbox-button signin leavebottom" onClick={()=>{
                        this.signOut()
                    }}>
                        Sign Out
                        {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}

                    </button>
                    
                    <p className="authbox-error">{this.state.errorMessage}</p>

                    {/* <button className="authbox-button signin" onClick={this.signUp}>Account Settings</button> */}
                </>
                leftHeader = "Hi there, " + firstName
                leftSubheader = "What awesome books are you reading today?"
                leftFooter = "Manage your account"
                leftFooterAction = "Account settings"
                leftFooterid="9"
                break;
            
            case "confirmSignUp": //3
                //Confirm Sign up
                screen=
                <>
                    <p className="authbox-p-head"><b>Confirm Verification Code</b></p>
                    <p className="authbox-p">To verify that your email exists, please enter the code sent to <b>{this.state.email}</b> </p>

                    <p className="authbox-p">Wrong email? <a className="authbox-link" onClick={this.setScreen} id="signUp" style={{display: "inline"}}>Go back</a></p>
                    <input onKeyPress ={this.onEnterKey} className="authbox-input" name="verificationCode" onChange={this.onChange} value={this.state.verificationCode} placeholder="Verification Code"></input>
                    <a className="authbox-link" onClick={this.resendVerificationCode}>Resend code</a>
                    <p className="authbox-p">Please check your spam if you cannot find the code.</p>

                    <p className="authbox-error">{this.state.errorMessage}</p>
                    <p className="authbox-regmes">{this.state.message}</p>
                    <button className="authbox-button signin" onClick={this.confirmSignUp}>
                        Submit
                        {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}
                    </button>
                </>
                leftHeader = "Create an account"
                leftSubheader = "Sign up to back up your work on the cloud, collaborate with teams, and share your documents and notebooks"
                leftFooter = "Already a member?"
                leftFooterAction = "Sign in"
                leftFooterid="signIn"
                break;
            
            case "verifyEmail": 
                screen=
                    <>
                        <p className="authbox-p-head"><b>Confirm Your Email ID</b></p>
                        <p className="authbox-p">We've sent a verification code to <b>{this.state.email}</b></p>
                        <p className="authbox-p">Wrong email? <a className="authbox-link" onClick={this.setScreen} id="signUp" style={{display: "inline"}}>Go back</a></p>
                        <input onKeyPress ={this.onEnterKey} className="authbox-input" name="verificationCode" onChange={this.onChange} value={this.state.verificationCode} placeholder="Verification Code"></input>
                        <a className="authbox-link" onClick={this.resendVerificationCode}>Resend code</a>
                        <p className="authbox-error">{this.state.errorMessage}</p>
                        <p className="authbox-regmes">{this.state.message}</p>
                        <button className="authbox-button signin" onClick={this.verifyCurrentUserAttributeSubmit}>
                            Submit
                            {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}
                        </button>
                    </>
                    leftHeader = "Create an account"
                    leftSubheader = "Sign up to back up your work on the cloud, collaborate with teams, and share your documents and notebooks"
                    leftFooter = "Already a member?"
                    leftFooterAction = "Sign in"
                    leftFooterid="signIn"
                break;

            case "passwordReset": //4 
            //Password Reset
                screen = 
                <>
                    <p className="authbox-p-head"><b>Password Reset</b></p>
                    {this.state.showConfirmPassword?
                        <p className="authbox-p">To update your password, enter the code sent to <b>{this.state.email}</b></p>
                        :null
                    }
                    <input onKeyPress ={this.onEnterKey} className="authbox-input" disabled={this.state.showConfirmPassword} type="email" name="email" onChange={this.onChange} value={this.state.email} placeholder="Email address"></input>
                    {
                        this.state.showConfirmPassword?
                        <>
                            <input onKeyPress ={this.onEnterKey} className="authbox-input" name="verificationCode" onChange={this.onChange} value={this.state.verificationCode} placeholder="Verification Code"></input>
                            <input onKeyPress ={this.onEnterKey} className="authbox-input" type="password" name="password" onChange={this.onChange} value={this.state.password}  placeholder="New password"></input>
                            <input onKeyPress ={this.onEnterKey} className="authbox-input" type="password" name="confirmPassword" onChange={this.onChange} value={this.state.confirmPassword}  placeholder="Confirm new password"></input>
                            <button className="authbox-button signin" onClick={this.forgotPasswordSubmit}>
                                Reset password
                                {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}

                            </button>

                        </>:
                        <>
                            <button className="authbox-button signin leavebottom" onClick={this.sendResetPasswordCode}>
                                Send me a code
                                {this.state.loading?<img className="loading-auth" alt="l" src={loading_gif}></img>:null}

                            </button>

                        </>

                    }
                    <br/>
                    <p className="authbox-error">{this.state.errorMessage}</p>
                    <p className="authbox-regmes">{this.state.message}</p>


                   

                </>
                leftHeader = "Welcome back"
                leftSubheader = "You're just a few clicks away from a great reading session"
                leftFooter = this.state.showConfirmPassword?"Wrong email?":"Remember your password?"
                leftFooterAction = this.state.showConfirmPassword?"Go back":"Sign in"
                leftFooterid="signIn"

                break;
                
            
            default: 
                screen=<div>Some error in react</div>

        }
        const {dims} = this.state
        return (
        <>
            NOTE: This page appears only on DEV mode (localhost) In the production app, auth is through app.desklamp.io
            {this.state.successPopup?<div className={`success-popup ${this.props.appTheme}`}>{this.state.successPopup}</div>:null}

            <div className={`authbox-container`}
                style={{
                    width:this.width,
                    height:this.height,
                    top:(dims[1] - this.height)/2,
                    left: (dims[0] - this.width)/2,
                    //replace this with proper window centering css later
                }}
            >
                
                {
                    this.props.device!=="mobile"?
                    <div className={`authbox-message authbox-column`} >
                        {/* <img src = {thinkerEle} className="thinkerEle"></img> */}
                        {/* <div className="authbox-message-container">
                            <p className="authbox-message-header">{leftHeader}</p>
                            <hr className="auth-hr"></hr>
                            <p className="authbox-message-text">{leftSubheader}</p>
                            <img src = {KitabLogo} className="auth-logo-Kitab"></img>

                        </div> */}
                        <div className="authbox-message-container2">
                            <div className="authbox-message-text">
                                <p className="authbox-message-header">{leftHeader}</p>
                                <p className="authbox-message-subheader">{leftSubheader}</p>
                            </div>
                            <div className="authbox-message-footer" onClick={this.setScreen} id={leftFooterid}>
                                <p className="leftFooter" id={leftFooterid}>{leftFooter}</p>
                                <p className="leftFooterAction" id={leftFooterid}>{leftFooterAction}</p>
                            </div>

                        </div>

                    </div>
                :null
                }
                
                <div className={`authbox-form authbox-column ${this.props.device}`}>
                    {!this.props.disableClose?<img alt="X" className="close-authbox" src={close} onClick={this.closeAuthBox}></img>:null}
                    <div className="authbox-form-container">
                        {screen}
                    </div>
                    
                    
                </div>
                
                
            </div>
        </>
        )
    }
}

export default AuthBox