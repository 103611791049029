import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import {initCategoryTree, getCategoryTree} from '../../utils/categoryTree'

function SidePanel(props){

    const checkActiveSubcategory = (subcat) =>{
        console.log("checking: ",subcat, props.subcategory )
        return subcat===props.subcategory
    }
    const [categoryTree, setCategoryTree] = useState(initCategoryTree)
    useEffect(()=>{
        getCategoryTree().then((newCategoryTree)=>{setCategoryTree(newCategoryTree)})
    }, [])

  return (
    <div className='sidepanel-container'>
        {
            categoryTree.map(category=>
            <CategoryList 
                title={category.category} 
                subcategories={category.subcategories} 
                checkActiveSubcategory = {checkActiveSubcategory}
            />
            )
        }
    </div>
  )
}


function CategoryList(props){
    let [searchParams, setSearchParams] = useSearchParams();

    return(
        <div className='category-container'>
            <h3>{props.title}</h3>
            <div className='sub-categories'>
                {
                    props.subcategories.map(subcat=>
                        <Link key={subcat} to={`/list?cat=${props.title}&subcat=${subcat}`}>
                            <div className={`subcat-title 
                                ${searchParams.get("subcat")===subcat?"active":"inactive"}
                            `}>
                                {subcat}
                            </div>    
                        </Link>
                    )
                }
            </div>


        </div>
    )
}

export default SidePanel