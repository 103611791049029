import React from 'react'
import './BookList.css'
import {Link} from "react-router-dom";
// import {books, getBookByID} from '../../utils/sampleData'


function BookList(props) {
    console.log("props", props.books, props.books.length)
    return (


    <div className="booklist-container">
        
        <h1>Catalogue {props.cat && !props.subcat ? " - " + props.cat : ''}</h1>
        
        <div className="booklist">
            {
                
                props.books.map(books=>
                    <div key = {books.category} className="categoryResultsList">
                        <div className={`queryInfo ${books.category?"show":"hide"}`}>
                            <span style={{"marginRight":"10px"}}>{books.category}</span> 
                            {books.subcategory?'>':''} 
                            <span style={{"marginLeft":"10px"}}  >{books.subcategory}</span> 
                        </div>

                        {
                            books.books.length?
                                books.books.map(book=>
                                    <Link key={book.id} to={`/book?id=${book.id}`}>
                                    <Book
                                        src={book.cover} author={book.author} 
                                        title={book.title} description={book.description}
                                        price={book.price}
                                    /> 
                                    </Link>    
                                )
                            :
                            <div className="noBooks">
                                No books here yet! Stay tuned.
                            </div>
                        }
                    </div>
                    
                   
                ) 
    
                
                
            }
        </div>
      </div>
    );
}

function Book(props){
    return(
        <div className='book-container row'>
            <div className="column cover-container">
                <img className="book-cover" src={props.src} alt="cover" />
            </div>
            <div className="column description-container">
                {/* <div className="title-author"> */}
                    <h3 className="book-title">{props.title}</h3>
                    <div className="author-text">{props.author}</div>
                {/* </div> */}
                <div className="price-text">₹ {props.price}</div>
                {/* <button className="dl-button">Buy Now</button> */}

            </div>
        </div>
    )

}

export default BookList