import React, { useEffect, useState } from "react";
import lib_logo from "../../icons/DesklampStore_color.svg";
import lib_logo_mob from "..//../icons/desklampStore.png";
import "./Navbar.css";
import { appURL } from "../../utils/url";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu/MobileMenu";

var ENV;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  ENV = "dev";
} else {
  ENV = "prod";
}

function Navbar(props) {
  /**
   * @bool productionAuthTesting: (Bool) Set this to true if you would like to test production authentication
   * functionalities in dev mode. If you are not working on auth related functionalities, set this to false
   * See docs for more: https://docs.google.com/document/d/1un8UMhyX60dm4vTzVwvVwJ2GV5obtqIPE6yAokpsIW8/edit?usp=sharing
   */
  const productionAuthTesting = false;

  const [popupVisibile, setPopupVisibility] = useState(false);

  const { userAttributes } = props;

  const togglePopup = () => {
    setPopupVisibility(!popupVisibile);
  };

  const signOutThroughApp = () => {
    togglePopup();
    props.signOutThroughApp();
  };

  const signOutThisSession = () => {
    togglePopup();
    props.signOutThisSession();
  };

  //code to set the popup menu
  var popupArray = [];
  var accountFunction = null;

  if (userAttributes) {
    //someone is signed in

    if (ENV == "dev") {
      //dev mode
      popupArray = [
        {
          title: "My Library",
          link: "https://localhost:5000",
        },
        {
          title: productionAuthTesting ? "Sign Out (This Session)" : "Sign Out",
          action: signOutThisSession,
        },
      ];
      if (productionAuthTesting) {
        popupArray.concat([
          {
            title: "Sign Out (Through APP)",
            action: signOutThroughApp,
          },
        ]);
      }
    } else {
      //prod mode
      popupArray = [
        {
          title: "My Library",
          link: appURL,
        },
        {
          title: "Sign Out", //sign out through APP only.
          action: signOutThroughApp,
        },
      ];
    }
  } else {
    //no user is signed in
    if (ENV == "dev") {
      //dev mode

      if (productionAuthTesting) {
        popupArray = [
          {
            title: productionAuthTesting ? "Sign In (This Session)" : "Sign In",
            action: () => {
              window.location.replace("/login");
            },
          },
          {
            title: "Init User",
            action: props.initUser,
          },
        ];
      } else {
        popupArray = null;
        accountFunction = () => {
          window.location.replace("/login");
        };
      }
    } else {
      //prod mode. No user signed in
      popupArray = null;
      accountFunction = props.manualSignIn;
      // No popup on sign in if no user is signed in. Directly sign in.
    }
  }
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMenuClick = () => {
    setMobileMenu(!mobileMenu);
  }
  

  return (
    <>
      <div className="navbar-container">
        <div className="floating float-left">
          <div className="logoContainer">
            <Link to="/">
              <img className="libLogo" src={lib_logo} alt="Desklamp Library" />
            </Link>
          </div>
        </div>
        <div className="floating float-center"></div>
        <div className="floating float-right">
          <button
            onClick={popupArray ? togglePopup : accountFunction}
            className="navlink signIn"
          >
            {userAttributes ? `Hi, ${userAttributes.given_name}` : "Sign In"}
          </button>
          {/* <button onClick={props.initUser}>Init User</button> */}
          <div className={`account-popup ${popupVisibile ? "show" : "hide"}`}>
            <AccountPopup popupArray={popupArray} />
          </div>
          <a
            href={appURL}
            className="navlink openDesklamp"
            target="_blank"
            rel="noreferrer"
          >
            Open Desklamp
          </a>
        </div>
      </div>
      <div className="container">
        <div className="navbar-container-mobile">
          <div className="floating float-left">
            <button className="ham-menu" onClick={handleMenuClick}>
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
          <div className="floating float-center">
            <div className="logoContainer-mobile">
              <Link to="/">
                <img
                  className="libLogo"
                  src={lib_logo_mob}
                  alt="Desklamp Library"
                />
              </Link>
            </div>
          </div>
          <div className="floating float-right">
            <button
              onClick={popupArray ? togglePopup : accountFunction}
              className="navlink-signIn-mobile"
            >
              {userAttributes ? (
                <div className="account-icon">{userAttributes.given_name.slice(0,1)}</div>
              ) : (
                "Sign In"
              )}
            </button>
            <div className={`account-popup ${popupVisibile ? "show" : "hide"}`}>
              <AccountPopup popupArray={popupArray} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${mobileMenu ? "showMenu" : "hideMenu"}`}>
        <div>
          <MobileMenu />
        </div>
      </div>
    </>
  );
}

function AccountPopup(props) {
  return (
    <>
      {props.popupArray
        ? props.popupArray.map((item, index) => (
            <div key={index} className="account-option" onClick={item.action}>
              {item.link ? (
                <a className="dl-a" href={item.link}>
                  {item.title}
                </a>
              ) : (
                <>{item.title}</>
              )}
            </div>
          ))
        : null}
    </>
  );
}

export default Navbar;
