export const createOrderRazorpay = /* GraphQL */ `
  query CreateOrderRazorpay($action: String!, $mode: String!, $bookID: ID!) {
    createOrderRazorpay(action: $action, mode: $mode, bookID: $bookID)
  }
`;
export const verifySignatureRazorpay = /* GraphQL */ `
  query VerifySignatureRazorpay(
    $action: String!
    $mode: String!
    $bookID: ID!
    $razorpay_payment_id: String!
    $razorpay_signature: String!
    $razorpay_order_id: String!
  ) {
    verifySignatureRazorpay(
      action: $action
      mode: $mode
      bookID: $bookID
      razorpay_payment_id: $razorpay_payment_id
      razorpay_signature: $razorpay_signature
      razorpay_order_id: $razorpay_order_id
    )
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      identityID
      sub
      owner
      profession
      provider
      documents {
        nextToken
        startedAt
      }
      desktop
      web
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;