let categoryTree = null

export const initCategoryTree=[
    {
        category:"Competitive Exams",
        subcategories: ["JEE", "NEET", "CUET"]
    },
    {
        category:"Law",
        subcategories: ["Religion", "Business", "Commercial", "Criminal", "Patents", "Entrepreneurship"]
    }
    // {
    //     category:"Career Development",
    //     subcategories: ["Consulting", "Quant & Aptitude", "HR"]
    // }
]
export const getCategoryTree = ()=>{
    if (categoryTree == null){
        // fetch the category tree here.
        return new Promise((resolve)=>{
            try {
                fetch("https://desklamp-store-thumbnails.s3.ap-south-1.amazonaws.com/public/CategoryTree/categoryTree.json").then((resp)=>
                    resp.json().then(
                        (json)=>{
                            console.log("JSON", json)
                            resolve(json)
                        }
                    )
                )
                // run().then((body)=>JSON.parse(body))
            } catch (error) {
                console.log(error)
                resolve([])
            }
            
        })
    }
    else{
        return Promise.resolve(categoryTree)
    }
}