import React, { useState, useEffect } from 'react'
import BookList from '../BookList/BookList'
import SidePanel from './SidePanel'
import './Home.css'
import {useSearchParams} from "react-router-dom"
import BookPage from '../BookPage/BookPage'
import awsmobile from '../../aws-exports'
import { getCategoryTree } from '../../utils/categoryTree'

const apiURLs = {
    "beta":"https://qwo6g2xflc.execute-api.ap-south-1.amazonaws.com/",
    "staging":"https://ohp8k3k9ne.execute-api.ap-south-1.amazonaws.com/",
    "main":"https://hv1y8m9fql.execute-api.ap-south-1.amazonaws.com/",

}

const arr = awsmobile['aws_user_files_s3_bucket'].split('-')
const branch = arr[arr.length - 1]
console.log("branch: ", branch)

const apiURL = apiURLs[branch]

async function fetchSingleBook(id, userAttributes){
    //return new Promise((resolve, reject)=>setTimeout(()=>resolve(getBookByID(id)), 2000))
    // Replace with API
    if (!userAttributes){
        let resp = await fetch(apiURL + "book?id=" + id)
        let json = await resp.json()
        console.log(json)
        return json
    }
    else {
        let resp = await fetch(apiURL + "book?id=" +id + '&user=' + userAttributes.sub)
        let json = await resp.json()
        console.log("BOOKDATA", json)
        return json
    }
    // return new Promise((resolve, reject)=>setTimeout(()=>resolve(getBookByID(id)), 2000))
}

async function fetchAllBooks(cat, subcat){
    //return new Promise((resolve, reject)=>setTimeout(()=>resolve(books), 2000))
    // replace with API
    let query = ""
    if(cat){
        if(subcat){
            query = "?cat=" + cat + "&subcat=" + subcat
        }
        else{
            query = "?cat=" + cat
        }
    }
    try{
    let resp = await fetch(apiURL + 'books' + query)
    let json = await resp.json()
    console.log(json)
    return json}
    catch(e){
        console.log(e)
        return []
    }
}


function Home(props) {
    let [loading, setLoading] = useState(true)
    let [bookData, setBookData] = useState({book: {}, alreadyBought: false})
    let [books, setBooks] = useState([])
    /**
     * @state books
     * array [] with objects of the form {category: String, subcategory: String, books: [{id, cover, price, title}]}
     */
    const [searchParams] = useSearchParams();


    useEffect(() =>{
        setLoading(true)
        const url = new URL(window.location.href)
        const category = url.searchParams.get("cat")
        const subcategory = url.searchParams.get("subcat")
        const id = url.searchParams.get("id")
        console.log("USE EFFECT HOME", props.type)
        if (props.type === "book" ){
            // Specific book page - we need to fire a request
            // book = getBookByID(id)
            fetchSingleBook(id, props.userAttributes).then(
                (newBookData)=>{
                    console.log("BOOK", newBookData)
                    // alert("resolved")
                    setBookData(newBookData)
                    setLoading(false)
                }
            )
            // setTimeout(()=>setLoading(false), 2000)
        }
        else if (props.type === "list"){
            // All books page - we should fetch the book details
            if(category && subcategory){
                fetchAllBooks(category, subcategory).then(
                    (bookResults)=>{
                        setBooks([
                            {
                                category,
                                subcategory,
                                books:bookResults
                            }
                        ])
                        setLoading(false)
                        }
                )
            }
            else if(category){
                fetchAllBooks(category, null).then(
                    (bookResults)=>{
                        // process books and pretend like subcategories are categories
                        let newBookList = {}
                        bookResults.forEach(element => {
                            if (element.subcategory in newBookList){
                                newBookList[element.subcategory].push(element)
                            }
                            else{
                                newBookList[element.subcategory] = [element]
                            }
                        });
                        console.log("BOOK FORMATTING", newBookList, bookResults)
                        setBooks(Object.entries(newBookList).map(
                            element=>
                            {return {
                                category: element[0],
                                subcategory: null,
                                books:element[1]
                            }}
                        ))
                        setLoading(false)
                        }
                )
            }
            else{
                //We are in the home page
                
                getCategoryTree().then(
                    (categoryTree)=>{
                        let promiseArray = categoryTree.map(({category})=>{
                            return new Promise((resolve)=>{
                                fetchAllBooks(category, "").then((bookResults)=>{
                                    resolve({
        
                                        category: category,
                                        subcategory: "",
                                        books:bookResults
                                    })
                                })
        
                            })
                        })
                        Promise.all(promiseArray).then((values)=>{
                            console.log("PROMISES",values)
                            setBooks(values)
                            setLoading(false)
                        })
                    }
                )
                // Version 1: All promises created. On resolution, the books array is set.

                  
                
                // Version 2: Each promise accepts the books added till now.  
                // It then after fetching for a category sets the state with all those books and then 
                // resolves with the new array. On resolving, the next promise maker is run. 
                // let emptyPage = categoryTree.map(({category})=>{
                //     return {

                //         category: category,
                //         subcategory: "",
                //         books:[]
                //     }
                // })
                // let promiseMaker = (booksTillNow, category)=>{
                    
                //     return new Promise((resolve)=>{
                        

                //         fetchAllBooks(category, "").then((bookResults)=>{
                //             console.log("Reading",booksTillNow, category)

                //             setLoading(false)
                //             console.log("About to add", booksTillNow, category)
                //             booksTillNow.push({

                //                 category: category,
                //                 subcategory: "",
                //                 books:bookResults
                //             })
                //             console.log("Added", booksTillNow)
                //             setBooks(booksTillNow)
                //             resolve(booksTillNow)
                //         })})
                // }
                // let result = Promise.resolve([])
                // categoryTree.forEach(({category})=>{
                //     result = result.then((booksTillNow)=>{
                //         console.log("Category Resolved!", category, booksTillNow)
                //         return promiseMaker(booksTillNow, category)
                //     })
                // })
                // result.then((finalBooks)=>{
                //     console.log("Absolute end", finalBooks)
                //     setBooks(finalBooks)})                
                
                
            }
            
            
        }
        
        // initUser()
    }, [props.type, props.userAttributes, searchParams])

    console.log("RENDERING HOME ", props)
    const url = new URL(window.location.href)
    const category = url.searchParams.get("cat")
    const subcategory = url.searchParams.get("subcat")
    const id = url.searchParams.get("id")
    return (
        <div className='home-container row'>
            <div className='column sidepanel'>
                <SidePanel />
            </div>
            <div className='column booklist'>
                {   loading ? <div>LOADING</div> :
                    props.type==="book"?
                        <BookPage bookData = {bookData}/>
                    :
                        <BookList books = {books} cat = {category} subcat = {subcategory}/>

                }
            </div>

        </div>
    )
}

export default Home