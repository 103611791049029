
import "./MobileMenu.css"
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import {initCategoryTree, getCategoryTree} from '../../../utils/categoryTree'

const MobileMenu = (props) => {
  const checkActiveSubcategory = (subcat) =>{
    console.log("checking: ",subcat, props.subcategory )
    return subcat===props.subcategory
  }
  const [categoryTree, setCategoryTree] = useState(initCategoryTree)
  useEffect(()=>{
      getCategoryTree().then((newCategoryTree)=>{setCategoryTree(newCategoryTree)})
  }, [])

  return (
  <div>
      {
          categoryTree.map(category=>
          <CategoryList 
              title={category.category} 
              subcategories={category.subcategories} 
              checkActiveSubcategory = {checkActiveSubcategory}
          />
          )
      }
  </div>
  )
}
function CategoryList(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className='category-list'>
      <h3>{props.title}</h3>
      <div>
        {props.subcategories.map((subcat) => (
          <Link key={subcat} to={`/list?cat=${props.title}&subcat=${subcat}`}>
            <div
              className={`subcat-title 
                                ${
                                  searchParams.get("subcat") === subcat
                                    ? "active"
                                    : "inactive"
                                }
                            `}
            >
              {subcat}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
export default MobileMenu
