import { type } from '@testing-library/user-event/dist/type';
import { CognitoUserSession, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import awsconfig from '../aws-exports'
//Source code for amazon-cognito-identity-js can be found here: https://github.com/aws-amplify/amplify-js/blob/81cbfd4d89c1f9fbf53691d1287f9639c9fe7b56/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1143

export const signInWithTokens = async(id_token, refresh_token, access_token, {errorCallback, successCallback}) =>{

    // console.log("error callback: ", errorCallback)
    const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: id_token }),
        RefreshToken: new CognitoRefreshToken({
            RefreshToken: refresh_token,
        }),
        AccessToken: new CognitoAccessToken({
            AccessToken: access_token,
        }),
    });

    let userPayload = session.getIdToken().decodePayload()

    console.log("Auth user payload: " , userPayload)

    let pool = new CognitoUserPool(
        {
            UserPoolId: awsconfig.aws_user_pools_id,
            ClientId: awsconfig.aws_user_pools_web_client_id,
            IdentityPoolId: awsconfig.aws_cognito_identity_pool_id
        }
    )
    let user = new CognitoUser(
        { 
            "Username" : userPayload['cognito:username'],
            "Pool" : pool
        }
    )

    // console.log("Auth before setSignIn Session: ", user)

    try{
        user.setSignInUserSession(session) //this is necessary for user.getUserAttributes
        console.log("Auth setSignIn Session: ", user)
    }
    catch(error){
        console.log("Could not set signIn Session: ", error)
    }

    var userAttributeList 

    try{
        //user.getUserAttributes sends the tokens to the cloud to verify if they are correct and can be used to sign the user in. 
        user.getUserAttributes((error, attributeList)=>{ 
            if(error){
                //the tokens provided are not correct. Most probably the access token is invalid or revoked (user may have signed out elsewhere)
                console.log("Auth getUserAttributes Error: ", error)
                errorCallback()
            }
            else{
                //the tokens are valid
                console.log("Auth got user attributes:  ", attributeList)
                successCallback()
            }
        })
    }
    catch(error){
        console.error("Auth couldn't get user Attributes!")
        errorCallback()
    }
    
    console.log("Auth userAttributeList: ", userAttributeList)
    

    


}