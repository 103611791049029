import React, { useState } from "react";
import PaymentCard from "../../components/PaymentCard";
// import {getBookByID} from '../../utils/sampleData'
import "./BookPage.css";
import { appURL } from "../../utils/url";

function BookPage(props) {
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  const book = props.bookData.book;
  const alreadyBought = props.bookData.alreadyBought;
  // let alreadyBought = false;

  return (
    // <div>BookPage {props.id}</div>

    <div className="bookpage-container row ">
      {!purchaseComplete ? (
        <>
          <div className="coverpage column">
            <img src={book.cover} alt="cover" className="bookpage-cover"></img>
            <div className="description-mobile">
              <div className="book-title">{book.title}</div>
              <div className="author-text">{book.author}</div>
              <div className="price-text">₹ {book.price}</div>
              {alreadyBought ? (
                <a
                  href={`${appURL}/book?id=${book.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="dl-button">Read this Book Now</button>
                </a>
              ) : (
                <PaymentCard
                  book={book}
                  setPurchaseComplete={setPurchaseComplete}
                />
              )}
            </div>
          </div>
          <div className="description column">
            <div className="description-book">
              <h2 className="book-title">{book.title}</h2>
              <div className="author-text">{book.author}</div>
              <div className="price-text">₹ {book.price}</div>
              {alreadyBought ? (
                <a
                  href={`${appURL}/book?id=${book.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="dl-button">Read this Book Now</button>
                </a>
              ) : (
                <PaymentCard
                  book={book}
                  setPurchaseComplete={setPurchaseComplete}
                />
              )}
            </div>
            <div
              className="description-bookpage"
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: book.description }}
            ></div>
          </div>
          <div
            className="description-bookpage-tab"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: book.description }}
          ></div>
        </>
      ) : (
        <div className="successMessage">
          <div className="message-container row">
            <div className="success-description column">
              <h1>Success!</h1>
              <p>
                <b>{book.title}</b> has been successfully added to your personal
                library on Desklamp.
              </p>
              <p className="endpara">What would you like to do next?</p>
              <div className="end-btns">
                <a
                  href={`${appURL}/book?id=${book.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="dl-button white success-btn">
                    Read Now
                  </button>
                </a>
                <a href={"/list?cat="+book.category}>
                  <button className="dl-button white success-btn">
                    Continue Shopping
                  </button>
                </a>
                <a href={appURL} target="_blank" rel="noreferrer">
                  <button className="dl-button white success-btn library-btn">
                    Go to Library
                  </button>
                </a>
              </div>
            </div>
            <div className="success-coverpage column">
              <img
                src={book.cover}
                alt="cover"
                className="bookpage-cover success"
              ></img>
            </div>
          </div>
          <div className="message-end">
            <p>What would you like to do next?</p>
            <div className="mobile-btns">
              <a
                href={`${appURL}/book?id=${book.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <button className="dl-button white success-btn">
                  Read Now
                </button>
              </a>
              <a href={"/list?cat="+book.category}>
                <button className="dl-button white success-btn">
                  Continue Shopping
                </button>
              </a>
              <a href={appURL} target="_blank" rel="noreferrer">
                <button className="dl-button white success-btn end-btns">
                  Go to Library
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookPage;
