import React, {useEffect, useState} from 'react'
import {API} from 'aws-amplify'
import {createOrderRazorpay, verifySignatureRazorpay} from '../graphql/queries'
import loading_gif from '../icons/loading_auth.gif'

function PaymentCard(props) {

    const {id, price} = props.book

    const [loadingOrder, setLoadingOrder] = useState(false)

    const errorMap = {
        /**
         * maps error messages given by AWS to error messages we should show the user
         */
        "No current user":"Please sign in or create an account to purchase a book"
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
    
    const __DEV__ = document.domain === 'localhost'

    
    // useEffect(() => {
    //     const loadRazorPayScript = async() =>{
    //         const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    //         if(!res) 
    //         {
    //             alert("Failed to load Razorpay! Please try later.")
    //             return
    //         }
    //     }
    // });

    const createOrder = async() =>{
        /**
         * Returns an orderID if the order was successfully created. Returns an error otherwise
         * @returns {orderID, error}
         */
        try{
            const orderObject = await API.graphql({ query: createOrderRazorpay, variables: {
                action: "createOrder",
                mode : "test",
                bookID: id
            }});
            console.log("Order placed: ", JSON.parse(orderObject.data.createOrderRazorpay))
            let resp = JSON.parse(orderObject.data.createOrderRazorpay)
            if (resp.statusCode === 202){
                props.setPurchaseComplete(true)
                return {orderID: null, error:"free"}
            }
            // setOrderId(JSON.parse(orderObject.data.createOrderRazorpay).order.id)
            const orderID =  JSON.parse(orderObject.data.createOrderRazorpay).order.id
            return {orderID, error: null}
        }
        catch(e){
            console.log("Error: ", e)
            // console.log(e.message, e.stack)
            // console.log(Object.getOwnPropertyNames(e))
            alert(errorMap[e.message] || "Unkown error placing order! Please try again later.")
            return {orderID: null, error: e}
        } 
    }

    const openRazorpayPortal =async(orderID) =>{
        /**
         * opens the razorpay portal and makes the payment
         * then verifies signature in the handler
         */

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

        var razorpay_payment_id, razorpay_order_id, razorpay_signature

        var options = {
            "key": __DEV__?"rzp_test_O4d89MssywY1n3":"PRODUCTION_KEY", // Enter the Key ID generated from the Dashboard
            "amount": price, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Desklamp",
            "description": "Test Transaction",
            "image": "https://desklamp.io/static/media/desklamp_logo_white.d1ff7d5ffa5ec950b7f8e6995025b2fc.svg",
            "order_id": orderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response){

                razorpay_payment_id = response.razorpay_payment_id
                razorpay_order_id = response.razorpay_order_id
                razorpay_signature = response.razorpay_signature

                console.log("razorpay_payment_id", response.razorpay_payment_id)
                console.log("razorpay_order_id",response.razorpay_order_id)
                console.log("razorpay_signature",response.razorpay_signature)

                console.log("id: ", id)
                const verificationResult = await API.graphql({ query: verifySignatureRazorpay, variables: {
                    action: "verifySignature",
                    mode : "test",
                    razorpay_payment_id,
                    razorpay_order_id,
                    razorpay_signature, 
                    bookID: id,
                }});
                
                const verficationResponse= JSON.parse(verificationResult.data.verifySignatureRazorpay)
                console.log("verificationResult",verficationResponse )

                if(verficationResponse.verificationResult){
                    //payment was successful!
                    props.setPurchaseComplete(true)


                }
                else{
                    //payment failed!

                }

        
            },
            "prefill": {
                "name": "Gaurav Kumar",
                "email": "gaurav.kumar@example.com",
                "contact": "9999999999"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#665aef"
            },
            "modal":{
                "animation":true
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        paymentObject.on('payment.failed', function(response){
            alert("Payment Failed: ", response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        })

    }

    const fullPaymentProcess = async() =>{
        setLoadingOrder(true)
        const {orderID, error} = await createOrder()
        setLoadingOrder(false)
        console.log("orderID", orderID, error)

        if(orderID){
            openRazorpayPortal(orderID)
        }
        else{
            console.error("error: ", error)
        }
    }

    return (
        <button className={`dl-button ${loadingOrder?"deactivated":""} `} onClick={fullPaymentProcess}>
            {loadingOrder?
            <span className="buy-button-content">
                Just a second
                <img className='loading_gif' src={loading_gif} alt="..." />
            </span>
            :"Buy Now"}
        </button>
    )
}

export default PaymentCard